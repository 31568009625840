import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import ReactMarkdown from "react-markdown"
import * as Styled from "./styled"
import RelatedPosts from "../../components/molecules/related-posts"
import Label from "../../components/atoms/label"
import { GatsbyImage } from "gatsby-plugin-image"
import { StaticSetting } from "../../utils/staticSetting"
import { useAppStateDispatch } from "../../providers/context-provider/app-context"

// markup
const PostDetailPage = ({
  data: {
    pageData: {
      nodes: [postDetailPageNode],
    },
    relatedPostsData: {
      nodes: [relatedPostsNode],
    },
    staticSetting,
    postId,
  },
}) => {
  const {
    id,
    seoMetaTags,
    locale,
    slug,
    title,
    abstract,
    label: {
      name: labelName,
      backgroundColor: labelColor,
      canonical: labelCanonical,
    },
    cover,
    hashtags,
    content,
    publishedAt,
    meta: { publishedAt: postPublishedAt, updatedAt: postUpdatedAt },
  } = postDetailPageNode || {}

  const staticSettings = StaticSetting(staticSetting?.nodes)
  const appStateDispatch: any = useAppStateDispatch()
  const googleMarkup = {
    "@context": "https://schema.org/",
    "@type": "NewsArticle",
    headline: title,
    name: title,
    author: [
      {
        "@type": "Organization",
        name: "Bnbdays",
        url: "https://bnbdays.com",
      },
    ],
    datePublished: postPublishedAt,
    dateModified: postUpdatedAt,
    description: abstract,
    image: [cover.url],
    publisher: {
      name: "Blog Bnbdays",
      url: "https://blog.bnbdays.com",
    },
  }

  useEffect(() => {
    let activeZoneUserTab = ""
    if (labelCanonical === "biambidero") {
      activeZoneUserTab = "/zona-biambidera"
    } else if (labelCanonical === "playero") {
      activeZoneUserTab = "/zona-playera"
    }
    appStateDispatch({
      type: "activeZoneUserTab",
      value: {
        activeZoneUserTab,
      },
    })
  }, [])

  return (
    <Styled.PostDetailPageWrapper>
      <HelmetDatoCms
        seo={seoMetaTags}
        htmlAttributes={{
          lang: locale,
        }}
      >
        <link rel="canonical" href={`https://blog.bnbdays.com/${slug}`}></link>
        <script type="application/ld+json">
          {JSON.stringify(googleMarkup)}
        </script>
      </HelmetDatoCms>
      <Styled.PostDetailPageContent>
        <Styled.PostHeadingContainer>
          <Styled.PostCoverImage
            alt="Foto cover del artículo"
            image={cover.gatsbyImageData}
          ></Styled.PostCoverImage>
          <Styled.PostDetailBannerContainer>
            <Label color={labelColor}>{labelName}</Label>
            <Styled.PostDetailBannerTitleText>
              {title}
            </Styled.PostDetailBannerTitleText>
            <Styled.PostDetailTitleBannerRibbonContainer>
              <Styled.PostDetailTitleBannerRibbonItemWrapper>
                <GatsbyImage
                  alt="hastag icon"
                  image={
                    staticSettings.get("hashtag_icon")?.media?.gatsbyImageData
                  }
                ></GatsbyImage>
                <p>
                  {hashtags.map(
                    (hashtag: { displayName: string; id: string }) => {
                      return `#${hashtag.displayName} `
                    }
                  )}
                </p>
              </Styled.PostDetailTitleBannerRibbonItemWrapper>
              {/* <Styled.PostDetailTitleBannerRibbonItemWrapper>
                <GatsbyImage
                  alt="like icon"
                  image={
                    staticSettings.get("like_icon")?.media?.gatsbyImageData
                  }
                ></GatsbyImage>
                <p>{staticSettings.get("like_icon")?.display}</p>
              </Styled.PostDetailTitleBannerRibbonItemWrapper> */}
              {/* <Styled.PostDetailTitleBannerRibbonItemWrapper>
                <GatsbyImage
                  alt="share icon"
                  image={
                    staticSettings.get("share_icon")?.media?.gatsbyImageData
                  }
                ></GatsbyImage>
                <p>{staticSettings.get("share_icon")?.display}</p>
              </Styled.PostDetailTitleBannerRibbonItemWrapper> */}
            </Styled.PostDetailTitleBannerRibbonContainer>
          </Styled.PostDetailBannerContainer>
        </Styled.PostHeadingContainer>
        <Styled.PostDetailContentWrapper>
          <ReactMarkdown
            className="markdown-content"
            skipHtml={false}
            children={content}
            components={{
              img({ node, className, children, ...props }) {
                let [alt, classNames] = props?.alt?.split("|") || []
                props = {
                  alt,
                  src: props.src,
                }
                if (alt.includes("type=iframe")) {
                  return (
                    <iframe
                      style={{ border: "0", margin: "auto", display: "block" }}
                      width="560"
                      height="315"
                      src={props.src}
                      title={classNames}
                      allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
                    ></iframe>
                  )
                } else {
                  return (
                    <>
                      <Styled.PostImage className={classNames} {...props}>
                        {children}
                      </Styled.PostImage>
                    </>
                  )
                }
              },
            }}
          />
          <Styled.PostPublishDateText>{publishedAt}</Styled.PostPublishDateText>
        </Styled.PostDetailContentWrapper>
      </Styled.PostDetailPageContent>
      {relatedPostsNode.visible && (
        <RelatedPosts
          data={{
            ...relatedPostsNode,
            parentPostId: id,
            ctaText: staticSettings.get("post_card_cta")?.display,
          }}
        ></RelatedPosts>
      )}
    </Styled.PostDetailPageWrapper>
  )
}

export default PostDetailPage

export const query = graphql`
  query PostDetailPageQuery($postId: String!, $locale: String!) {
    navBarData: allDatoCmsNavigationBar(filter: { locale: { eq: $locale } }) {
      nodes {
        contactLink
        contactText
        locale
        pressLink
        pressText
        zonaBiambideraText
        zonaPlayeraText
        showZonaBiambidera
        showZonaPlayera
        showPress
        showContact
        showSearch
        languageDisplayName
        linkedinUrl
        facebookUrl
        instagramUrl
      }
    }
    footer: allDatoCmsFooter(filter: { locale: { eq: $locale } }) {
      nodes {
        links {
          name
          uri
        }
      }
    }
    pageData: allDatoCmsPost(filter: { id: { eq: $postId } }) {
      nodes {
        id
        seoMetaTags {
          tags
        }
        locale
        slug
        title
        label {
          name
          backgroundColor
          canonical
        }
        cover {
          url
          alt
          gatsbyImageData(
            width: 1200
            placeholder: BLURRED
            forceBlurhash: false
          )
        }
        hashtags {
          displayName
          id
          canonizedName
        }
        content
        publishedAt
        abstract
        meta {
          publishedAt
          updatedAt
        }
      }
    }
    relatedPostsData: allDatoCmsRelatedPostsCarousel(
      filter: { locale: { eq: $locale } }
    ) {
      nodes {
        title
        seeMore
        visible
        relatedPostsList {
          id
          title
          slug
          abstract
          label {
            name
            backgroundColor
          }
          cover {
            alt
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
        }
      }
    }
    staticSetting: allDatoCmsStaticSetting(
      filter: { locale: { eq: $locale }, tag: { eq: "post" } }
    ) {
      nodes {
        name
        display
        media {
          gatsbyImageData
        }
      }
    }
  }
`

import React, { useEffect, useRef, useState } from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import * as Styled from "./styled"
import PostCard from "../../blocks/post-card"
import { Link, navigate } from "gatsby"

const RelatedPosts = ({ data }) => {
  const { title, relatedPostsList, ctaText, slug, parentPostId } = data

  const carouselRef = useRef(null)
  const [postList, setPostList] = useState([
    ...relatedPostsList.filter((post) => post.id !== parentPostId),
    {
      type: "list-end",
    },
  ])
  const [carouselView, setCarouselView] = useState([0, 1])

  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setCarouselView([0, 0])
    }
  }, [])
  const shiftCarrousel = (direction) => {
    if (direction === "right" && carouselView[1] + 1 <= postList.length - 1) {
      for (let card of (carouselRef?.current as any).children) {
        if (card.id?.split("-")?.[1] == carouselView[1] + 1) {
          card.scrollIntoView({
            block: "nearest",
          })
        }
      }
      setCarouselView((oldValue) => [oldValue[0] + 1, oldValue[1] + 1])
    } else if (direction === "left" && carouselView[0] - 1 >= 0) {
      for (let card of (carouselRef?.current as any).children) {
        if (card.id?.split("-")?.[1] == carouselView[0] - 1) {
          card.scrollIntoView({
            block: "nearest",
          })
        }
      }
      setCarouselView((oldValue) => [oldValue[0] - 1, oldValue[1] - 1])
    }
  }

  return (
    <Styled.RelatedPostsWrapper>
      <Styled.RelatedPostsHeader>
        <img
          src="../images/arrow_left.png"
          style={{
            visibility: `${carouselView[0] === 0 ? "hidden" : "visible"}`,
          }}
          alt="Flecha izquierda"
          height={30}
          className="arrow-button"
          onClick={() => shiftCarrousel("left")}
        />
        <h3>{title}</h3>
        <img
          src="../images/arrow_right.png"
          style={{
            visibility: `${
              carouselView[1] === postList?.length - 1 ? "hidden" : "visible"
            }`,
          }}
          alt="Flecha derecha"
          height={30}
          className="arrow-button"
          onClick={() => shiftCarrousel("right")}
        />
      </Styled.RelatedPostsHeader>
      <Styled.RelatedPostsCarrousel>
        <Styled.RelatedPostsCarrouselContainer ref={carouselRef}>
          {postList.map((post, index) => {
            if (post.type !== "list-end") {
              return (
                <PostCard
                  id={`post-${index}`}
                  key={`post-${index}`}
                  data={{ ...post, ctaText }}
                ></PostCard>
              )
            }
            // else {
            //   return (
            //     <Styled.SeeMore
            //       id={`post-${index}`}
            //       key={`post-${index}`}
            //       className="post-card"
            //     >
            //       <Link to={slug}>{ctaText}</Link>
            //     </Styled.SeeMore>
            //   )
            // }
          })}
        </Styled.RelatedPostsCarrouselContainer>
      </Styled.RelatedPostsCarrousel>
    </Styled.RelatedPostsWrapper>
  )
}

export default RelatedPosts

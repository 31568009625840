import styled from "styled-components"
import { getBreakpoint } from "../../../utils/theme"

export const RelatedPostsWrapper = styled.div`
  width: 100%;
  margin: auto;
  box-sizing: border-box;
`
export const RelatedPostsHeader = styled.div`
  widht: 100%;
  padding: 1rem;
  margin: auto;
  max-width: var(--content-wide-max-width);
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 1rem auto;
  }

  .arrow-button {
    cursor: pointer;
  }
`

export const RelatedPostsCarrousel = styled.div`
  box-sizing: border-box;
  background-color: var(--color-gray);
  width: 100%;
  padding: 1rem;
  padding-top: 2rem;
  padding-bottom: 2.25rem;
`

export const RelatedPostsCarrouselContainer = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  max-width: var(--content-wide-max-width);
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;

  overflow: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  .post-card {
    scroll-snap-align: start;
    position: relative;
  }
`
export const SeeMore = styled.div`
  display: flex;
  min-height: 13.4375rem;
  min-width: 30.125rem;
  align-items: center;
  justify-content: center;

  ${getBreakpoint("mobile l", "MAX")} {
    min-width: 100%;
    justify-content: flex-start;
    padding-left: 6rem;
  }
`

import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { getBreakpoint } from "../../utils/theme"

export const PostDetailPageContent = styled.div`
  max-width: 67.5rem;
  margin: auto;
  margin-top: calc(var(--nav-height) + var(--nav-social-height));
  padding: 1.375rem 1rem 1rem 1rem;
  section {
    padding: 0.5rem;
  }

  ${getBreakpoint("tablet")} {
    section {
      padding: 2rem 0.5rem;
    }
  }

  p:has(img) {
    text-align: center;
    font-size: 0.9rem;
    color: var(--color-gray-dark);
  }
`

export const PostDetailPageWrapper = styled.main`
  min-height: calc(
    100vh - var(--nav-height) - var(--nav-social-height) - var(--footer-height)
  );
  background-color: #f2f2f2;
`
export const PostHeadingContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 21rem 4rem min-content;
  grid-template-areas: "cover" "banner-half-top" "banner-half-bottom";
  padding-bottom: 1.5rem;

  ${getBreakpoint("mobile l", "MAX")} {
    grid-template-rows: 10rem 4rem min-content;
  }
`

export const PostCoverImage = styled(GatsbyImage)`
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: cover;
  grid-row-end: banner-half-top;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  max-width: 67.5rem;
  width: 100%;
  border-radius: 0.6875rem;
`

export const PostDetailBannerContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: banner-half-top;
  grid-row-end: banner-half-bottom;
  box-sizing: border-box;

  background: #ffffff;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  border-radius: 0.625rem;
  padding: 0.75rem;
  max-width: var(--content-max-width);
  margin: auto;

  padding: 1rem 2rem;

  .label {
    margin: auto;
  }
  z-index: 5;
`

export const PostDetailBannerTitleText = styled.h1`
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  font-weight: 700;
  color: var(--color-primary-dark);
  margin-top: 0.5rem;
`
export const PostDetailTitleBannerRibbonItemWrapper = styled.div`
  position: relative;
  display: flex;
  min-width: 0;
  align-items: center;
  gap: 0.5rem;

  p {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #707070;
    padding-right: 0.5rem;
    line-height: 1.4rem;
    font-weight: 500;
  }
`
export const PostDetailTitleBannerRibbonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  min-width: 0;
  gap: 0.5rem;
`

export const PostDetailContentWrapper = styled.div`
  max-width: 50rem;
  margin: auto;
`

export const PostImage = styled.img`
  width: 100%;
  height: 25rem;
  object-fit: cover;
  border-radius: 0.6875rem;

  &.cover {
  }

  &.center {
    display: block;
    object-fit: cover;
    max-width: 35rem;
    width: 100%;
    margin: auto;
  }
  &.left {
    display: block;
    object-fit: cover;
    max-width: 35rem;
    width: 100%;
  }

  &.right {
    display: block;
    object-fit: cover;
    max-width: 35rem;
    width: 100%;
    margin: 0 0 0 auto;
  }

  &.small {
    height: 15rem;
    max-width: 15rem;
  }

  &.banner {
    height: 15rem;
  }

  ${getBreakpoint("mobile l", "MAX")} {
    height: 15rem;
  }
`

export const PostPublishDateText = styled.p`
  font-weight: 700;
  color: #707070;
  font-size: 1rem;
  padding-top: 2rem;
`
